<h1 mat-dialog-title>Change Facility</h1>
<mat-dialog-content>
	<mat-form-field>
		<mat-label>Current Facility</mat-label>
		<mat-select [(ngModel)]="selectedFacility">
			@for (facility of userStore.userInfo()?.facilities; track facility.facilityId) {
				<mat-option [value]="facility.facilityId">
					{{facility.name}}
				</mat-option>
			}
		</mat-select>
	</mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close type="button">Cancel</button>
	<button type="submit" [mat-dialog-close]="selectedFacility" mat-raised-button color="primary">Save</button>
</mat-dialog-actions>
