
import { CommonModule } from '@angular/common';
import { Component, Input, input } from '@angular/core';

@Component({
	selector: 'nta-layout',
	standalone: true,
	imports: [CommonModule],
	template: `
	<div class="h-full min-h-[calc(100vh-4rem)] w-full px-4 tablet:px-6 laptop:px-8">
		<div [class.max-w-[90rem]]="template() === 'normal'" [class.max-w-[60rem]]="template() === 'skinny'" class="mx-auto">
			<ng-content />
		</div>
	</div>
	`
})

export class NtaLayoutComponent {
	template = input<'normal' | 'skinny'>('normal');
}
