<h1 mat-dialog-title>Add User</h1>
<mat-dialog-content>
	<form id="addUserForm" class="grid w-80 gap-5 py-5" #addUserForm="ngForm" (submit)="submit(addUserForm.form)">
		<mat-form-field>
			<mat-label>Display Name</mat-label>
			<input type="text" matInput name="displayName" required ngModel>
			<mat-error>Name is required</mat-error>
		</mat-form-field>
		<mat-form-field>
			<mat-label>Email</mat-label>
			<input type="text" matInput name="email" required ngModel #emailInput="ngModel" email>
			<mat-error>Valid email is required</mat-error>
		</mat-form-field>
	</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
	<button mat-button mat-dialog-close type="button">Cancel</button>
	<button type="submit" form="addUserForm" mat-raised-button color="primary">Save</button>
</mat-dialog-actions>
