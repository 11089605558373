import { inject, Injectable } from '@angular/core';
import { Auth, authState, User } from '@angular/fire/auth';
import { collection, CollectionReference, doc, docData, DocumentReference, Firestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Corporation, Enterprise, Facility, MyUserInfo } from '@ss/typings/facility';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { RouteCheckService } from './route-check.service';

export class NtaUserLoadedConfig {
	fbUser: User;

	user: MyUserInfo;

	ntaEnabled: boolean;

	hasFacilityAccess: boolean;

	hasEnterpriseAccess: boolean;

	hasCorporationAccess: boolean;
}

@Injectable({ providedIn: 'root' })
export class NtaUserService {

	fbUser: User;

	user: MyUserInfo;

	facility: Facility;

	loaded: Observable<NtaUserLoadedConfig>;

	enterprise: Enterprise;

	corporation: Corporation;

	constructor(
		private auth: Auth,
		private firestore: Firestore,
	) {
		this.loaded = authState(this.auth).pipe(
			switchMap((user) => {
				if (!user) return of(null);

				this.fbUser = user;
				return docData<MyUserInfo>(doc(collection(this.firestore, '/users') as CollectionReference<MyUserInfo>, user.uid) as DocumentReference<MyUserInfo>, { idField: 'userId' });
			}),
			switchMap((user: MyUserInfo) => {
				let facility$: Observable<Facility> = of(null);
				let enterprise$: Observable<Enterprise> = of(null);
				let corporation$: Observable<Corporation> = of(null);
				if (user) {
					this.user = user;
					const facilityId = user.proxyFacilityId || user.currentFacilityId;
					facility$ = docData<Facility>(doc(collection(this.firestore, '/facilities') as CollectionReference<Facility>, facilityId) as DocumentReference<Facility>, { idField: 'facilityId' });
					if (user.enterpriseId) {
						enterprise$ = docData<Enterprise>(doc(collection(this.firestore, '/enterprises') as CollectionReference<Enterprise>, user.enterpriseId) as DocumentReference<Enterprise>, { idField: 'enterpriseId' });
					}
					if (user.corporationId) {
						corporation$ = docData<Corporation>(doc(collection(this.firestore, '/corporations') as CollectionReference<Corporation>, user.corporationId) as DocumentReference<Corporation>, { idField: 'corporationId' });
					}
				}

				return combineLatest([facility$, enterprise$, corporation$]);
			}),
			tap(([facility, enterprise, corporation]) => {
				if (facility) this.facility = new Facility(facility);
				if (enterprise) this.enterprise = new Enterprise(enterprise);
				if (corporation) this.corporation = new Corporation(corporation);
			}),
			map(() => {
				const user = this.facility?.ntaUsers.find((u) => u.userId === this.user.userId);
				const hasCorporationAccess = !!(this.corporation && this.corporation.ntaUsers[this.user.userId]);
				const hasEnterpriseAccess = !!(this.enterprise && this.enterprise.ntaUsers[this.user.userId]) || this.user?.isSuperAdmin || hasCorporationAccess && this.corporation.enterpriseIds.includes(this.enterprise?.enterpriseId || '');
				const hasFacilityAccessThroughSuperAdmin = this.user?.isSuperAdmin;
				const hasFacilityAccessThroughCorporation = hasCorporationAccess && this.corporation.enterpriseIds.includes(this.facility.enterpriseId);
				const hasFacilityAccessThroughFacility = ['screener', 'admin'].includes(user?.role);
				const hasFacilityAccessThroughEnterprise = hasEnterpriseAccess && this.enterprise?.facilityIds?.includes(this.facility.facilityId);
				const hasFacilityAccess = hasFacilityAccessThroughSuperAdmin || hasFacilityAccessThroughCorporation || hasFacilityAccessThroughFacility || hasFacilityAccessThroughEnterprise;
				return {
					fbUser: this.fbUser,
					user: this.user,
					ntaEnabled: this.facility?.ntaEnabled ?? false,
					hasFacilityAccess,
					hasEnterpriseAccess,
					hasCorporationAccess
				};
			}),
		);
	}
}

export const enableMfaGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}
		if (!loaded.ntaEnabled) {
			router.navigate(['account/coming-soon']);
			return false;
		}
		if (!loaded.hasFacilityAccess) {
			router.navigate(['account/unauthorized']);
			return false;
		}
		if (loaded.user.hasTempPassword) {
			router.navigate(['account/update-password']);
			return false;
		}
		if (!loaded.fbUser.emailVerified) {
			router.navigate(['account/verify-email']);
			return false;
		}
		if (loaded.user.mfaEnabled) {
			router.navigate(['']);
			return false;
		}
		return true;
	}));
};

export const verifyEmailGaurd = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}
		if (!loaded.ntaEnabled) {
			router.navigate(['account/coming-soon']);
			return false;
		}
		if (!loaded.hasFacilityAccess) {
			router.navigate(['account/unauthorized']);
			return false;
		}
		if (loaded.user.hasTempPassword) {
			router.navigate(['account/update-password']);
			return false;
		}
		if (loaded.fbUser.emailVerified) {
			router.navigate(['']);
			return false;
		}
		return true;
	}));
};

export const updatePasswordGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}
		if (!loaded.ntaEnabled) {
			router.navigate(['account/coming-soon']);
			return false;
		}
		if (!loaded.hasFacilityAccess) {
			router.navigate(['account/unauthorized']);
			return false;
		}
		if (!loaded.user.hasTempPassword) {
			router.navigate(['']);
			return false;
		}
		return true;
	}));
};

export const unauthorizedGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}
		if (!loaded.ntaEnabled) {
			router.navigate(['account/coming-soon']);
			return false;
		}
		if (loaded.hasFacilityAccess) {
			router.navigate(['']);
			return false;
		}
		return true;
	}));

};

export const comingSoonGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}
		if (loaded.ntaEnabled) {
			router.navigate(['']);
			return false;
		}
		return true;
	}));
};

export const ntaMainGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	const routeCheck = inject(RouteCheckService);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.fbUser) {
			router.navigate(['account/signin']);
			return false;
		}

		if (!loaded.ntaEnabled) {
			router.navigate(['account/coming-soon']);
			return false;
		}

		if (!loaded.hasFacilityAccess) {
			router.navigate(['account/unauthorized']);
			return false;
		}

		if (loaded.user.hasTempPassword) {
			router.navigate(['account/update-password']);
			return false;
		}

		if (!loaded.fbUser.emailVerified) {
			router.navigate(['account/verify-email']);
			return false;
		}

		if (!loaded.user.mfaEnabled) {
			router.navigate(['account/mfa']);
			return false;
		}

		if (routeCheck.url) {
			routeCheck.navigateToExistingUrl();
		}

		return true;
	}));
};

export const enterpriseGuard = () => {
	const ntaUser = inject(NtaUserService);
	const router = inject(Router);

	return ntaUser.loaded.pipe(map((loaded) => {
		if (!loaded.hasEnterpriseAccess && !loaded.hasCorporationAccess) {
			router.navigate(['facility']);
			return false;
		}

		return true;
	}));
};
