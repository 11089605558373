import { Component } from '@angular/core';
import { FormGroup, FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
	selector: 'nta-add-user',
	templateUrl: './add-user.dialog.html',
	styleUrls: ['./add-user.dialog.scss'],
	standalone: true,
	imports: [
		MatDialogModule,
		FormsModule,
		MatFormFieldModule,
		MatInputModule,
		MatButtonModule,
	],
})
export class AddUserDialog {
	constructor(private dialogRef: MatDialogRef<AddUserDialog>) {}

	submit(addUserForm: FormGroup) {
		if (addUserForm.valid) {
			this.dialogRef.close({ ...addUserForm.value });
		}
	}
}
