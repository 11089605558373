import { inject, Injectable } from '@angular/core';
import { NavigationCancel, Router } from '@angular/router';
import { filter, first } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RouteCheckService {

	router = inject(Router);

	url = '';

	constructor() {
		this.router.events
			.pipe(first<any, NavigationCancel>((event) => event instanceof NavigationCancel))
			.subscribe((event) => {
				this.url = event.url;
			});
	}

	navigateToExistingUrl() {
		this.router.navigateByUrl(this.url || '/');
		this.url = '';
	}
}
