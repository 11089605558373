import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal } from '@angular/core';
import { Auth, authState } from '@angular/fire/auth';
import { collection, CollectionReference, doc, docData, DocumentReference, Firestore, updateDoc } from '@angular/fire/firestore';
import { firebaseConfig } from '@ss/typings/config';
import { Enterprise, Facility, MyUserInfo, NtaUser } from '@ss/typings/facility';
import { NewUser, Role } from '@ss/typings/nta-hunter';
import { BehaviorSubject, filter, map, of, Subscription, switchMap, tap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NtaFacilityStore {

	user = signal<MyUserInfo>(new MyUserInfo({}));

	facility = signal(new Facility({}));

	loading = signal(true);

	enterprise = signal(new Enterprise({}));

	readonly url = `${firebaseConfig.functionsUrl}/ntaApi`;

	loaded$ = new BehaviorSubject<Facility>(null);

	loaded = this.loaded$.asObservable().pipe(filter((f) => !!f));

	private subscription: Subscription;

	isAdmin = computed(() => {
		const hasAdminAccessThroughFacility = this.facility()?.ntaUsers?.find((u) => u.userId === this.user()?.userId)?.role === 'admin';
		const hasAdminAccessThroughSuperAdmin = this.user()?.isSuperAdmin;
		const hasAdminAccessThroughEnterprise = this.enterprise()?.ntaUsers && this.user().userId in this.enterprise().ntaUsers;

		return hasAdminAccessThroughEnterprise || hasAdminAccessThroughSuperAdmin || hasAdminAccessThroughFacility;
	});

	sortedUsers = computed(() => this.facility().ntaUsers.slice().sort((a, b) => a.name?.localeCompare(b.name)));

	hasSingleAdmin = computed(() => this.facility().ntaUsers.filter((user) => user.role === 'admin').length === 1);

	constructor(private firestore: Firestore, private auth: Auth, private http: HttpClient) {
		this.init();
	}

	init() {
		if (this.subscription) this.subscription.unsubscribe();
		this.subscription = authState(this.auth).pipe(
			tap((user) => {
				if (!user) {
					this.user.set(null);
					this.facility.set(null);
				}
			}),
			filter((user) => !!user),
			map((user) => user.uid),
			switchMap((uid) => docData<MyUserInfo>(doc(collection(this.firestore, '/users') as CollectionReference<MyUserInfo>, uid) as DocumentReference<MyUserInfo>, { idField: 'userId' })),
			tap((u) => this.user.set(u)),
			switchMap((u) => {
				const facilityId = u.proxyFacilityId || u.currentFacilityId;
				return docData<Facility>(doc(collection(this.firestore, '/facilities') as CollectionReference<Facility>, facilityId) as DocumentReference<Facility>, { idField: 'facilityId' });
			}),
			switchMap((facility) => {
				this.facility.set(new Facility(facility));
				if (!facility.enterpriseId) return of(null);
				return docData<Enterprise>(doc(collection(this.firestore, '/enterprises') as CollectionReference<Enterprise>, facility.enterpriseId) as DocumentReference<Enterprise>, { idField: 'enterpriseId' });
			})
		).subscribe((enterprise) => {
			this.loading.set(false);
			this.enterprise.set(new Enterprise(enterprise));
			this.loaded$.next(this.facility());
		});
	}

	updateUser(user: NtaUser, role: Role) {
		const fUser = this.facility().ntaUsers.find((u) => u.userId === user.userId);
		fUser.role = role;
		const updatedUsers = this.facility().ntaUsers.reduce((users, u) => {
			users[u.userId] = u;
			return users;
		}, {});
		updateDoc(doc(this.firestore, `facilities/${this.facility().facilityId}`), { ntaUsers: updatedUsers });
	}

	addUser(newUser: NewUser) {
		this.loading.set(true);
		return this.http.put<NtaUser>(`${this.url}/user`, newUser)
			.pipe(tap(() => this.loading.set(false)));
	}

	removeUser(user: NtaUser) {
		this.loading.set(true);
		return this.http.delete(`${this.url}/user/${user.userId}`)
			.pipe(tap(() => this.loading.set(false)));
	}
}
