import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { UserStore } from '@ss/simpliscreen/facility';

@Component({
	selector: 'nta-change-facility',
	templateUrl: './change-facility.dialog.html',
	styleUrls: ['./change-facility.dialog.scss'],
	standalone: true,
	imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, MatSelectModule, FormsModule, CommonModule]
})
export class ChangeFacilityDialog {

	selectedFacility: string;

	constructor(public userStore: UserStore) {
		this.userStore.loaded.subscribe(() => this.selectedFacility = this.userStore.userInfo().currentFacilityId);
	}
}
